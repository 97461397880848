import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { RegistrationComponent } from './registration/registration.component';
import { LoginComponent } from './login/login.component';
import { ReportsComponent } from './reports/reports.component';
import { UploadcsvComponent } from './uploadcsv/uploadcsv.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewcsvdetailsComponent } from './viewcsvdetails/viewcsvdetails.component';

const routes: Routes = [
  {path :'', component:DashboardComponent},
  { path: 'registration', component: RegistrationComponent },
  { path: 'reports', component: ReportsComponent },
  { path: 'login', component: LoginComponent },
  { path: 'upload', component:UploadcsvComponent},
  {
    path:'csvview/:id',component:ViewcsvdetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
