import { Component, OnInit } from '@angular/core';
import { DataService } from './../services/data.service';
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

	reportsData : any;
	columns : any;
	filter_tag:any;
	filter_tag_value:any;
	search_ready:boolean;
	placeholder:string;

	/*toppings = new FormControl();

  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];*/

  constructor(private dataservice:DataService,private route:Router) {
  this.search_ready =  false; }

  ngOnInit(): void {
  	this.dataservice.getRportsData().subscribe((res: any)=>{
  		this.reportsData = res;
  		let keys = [];
  		for (var key in this.reportsData[0]) {
  		keys.push(key);

  		}
  		this.columns = keys;
  		console.log('this.columns',this.columns)
  		
  	})
  }
  openmodal()
  {
	  console.log("worked0");
	  // Get the modal
      var modal = document.getElementById("myModal");
      
      modal.style.display = "block";
// // Get the button that opens the modal
// var btn = document.getElementById("myBtn");

// // Get the <span> element that closes the modal
// var span = document.getElementsByClassName("close")[0];


// When the user clicks on <span> (x), close the modal
// span.onclick = function() {
//   modal.style.display = "none";
// }

// When the user clicks anywhere outside of the modal, close it
window.onclick = function(event) {
  if (event.target == modal) {
    modal.style.display = "none";
  }
}
  }

  closemodal()
  {
	var modal = document.getElementById("myModal");
	modal.style.display = "none";
  }

  TagChanged(tagName:any) {
  	console.log('tagName',tagName)
  	if(tagName != '0') {
  		this.search_ready = true;
  	} else {
  		this.search_ready = false;
  	}
  	this.placeholder = "Enter "+tagName;
  	this.dataservice.getDistinctValues(tagName).subscribe((res: any)=>{
  		//this.reportsData = res;	
  	})
  }
  SearchTag(event:any){
  
	  if(event.charCode == '13'){
	  	console.log('tagValue',event.target.value);
	  	let obj = {};
	  	obj['key'] = this.filter_tag;
	  	obj['value'] = this.filter_tag_value;
	  	this.dataservice.getRportsDataByTag(obj).subscribe((res: any)=>{
	  		this.reportsData = res;	
	  	})
	  }
  }

  upload()
  {
    this.route.navigate(["upload"]);
  }


}
