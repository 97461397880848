import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  public email: string = "";
  public pass: string = "";
  public c_pass :string = "";
  
  constructor() { }

  ngOnInit(): void { 
  }
  submitRegistration(){

  }
}
