<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p style="font-size: 20px; color: white">uploading...</p>
</ngx-spinner>

                    <form [formGroup]="uploadcsv" enctype="multipart/form-data" (ngSubmit)="onSubmit()">
                        <div class="edit-profile container">
                            <div class="form-list-profile">
                               <div class="form-wrap">
                                   <!-- Progress Bar -->
        <div class="progress form-group" *ngIf="progress > 0">
         <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress">
         </div>
     </div>
                                <div class="input-wrap"><input type="file" formControlName="file"
                                 required (change) ="uploadCSV($event)" accept=".xlsx, .xls, .csv">
                                 <span class="text-danger"
                                 *ngIf="(registerFormControl.file.touched || submitted) && registerFormControl.file.errors?.required">
                                 File is required
                             </span>
                              </div>
                                <button
                                  [disabled]="!uploadcsv"
                                  class="btn btn-primary"
                                >
                                  Submit

                                </button>
                               </div>
                          </div>
                        </div>
                      </form>
