<div class="container">
  <div class="row">
    <ag-grid-angular
    style="width: 100%; height:550px"
    class="ag-theme-alpine"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    
    [pagination]=true
    [paginationPageSize]="paginationPageSize"
>
</ag-grid-angular>
 </div>
 </div>