 <div class="header">
         <div class="container">
            <div class="row">
               <div class="col-md-2">
                  <a href="#"><img src="assets/images/lyfted_logo.png" width="70%" id="img-logo" /></a>
               </div>
               <div class="col-md-10">
                  <nav>
                     <div class="hamburger">
                        <div class="line1"></div>
                        <div class="line2"></div>
                        <div class="line3"></div>
                     </div>
                     <ul class="nav-links">
                        <li><a  [routerLink]="['/']"><i class="fa fa-home" aria-hidden="true"></i>Dashboard</a></li>
                        <li><a  [routerLink]="['/']"><i class="fa fa-user" aria-hidden="true"></i>Manage List</a></li>
                        <li><a  [routerLink]="['/reports']"><i class="fa fa-external-link-square" aria-hidden="true"></i>Reports</a></li>
                        <li>
                        <div class="dropdown-area">
                          <div class="notify-button">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-bell"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                          </div>
                          <!-- <ul class="notify-area">
                            <li>Example</li>
                            <li>Other Example</li>
                            <li>More One Example</li>
                          </ul> -->
                        </div>   
                        </li>
                        <li>
                           <nav class="navbar navbar-dark  navbar-expand-sm">
  
  <div class="collapse navbar-collapse" id="navbar-list-4">
    <ul class="navbar-nav">
        <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="assets/images/44.png"  class="rounded-circle">
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <a class="dropdown-item" href="#">Dashboard</a>
          <a class="dropdown-item" href="#">Edit Profile</a>
          <a class="dropdown-item" href="#">Log Out</a>
        </div>
      </li>   
    </ul>
  </div>
</nav>
</li>
</ul>
</nav>
</div>
</div>
</div>
</div>