import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Meta } from '@angular/platform-browser';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public email: string = "";
  public pass: string = "";
  public token: any = "";

  constructor(private ps: AuthService,private metaService: Meta) {  
       // this.addTag();
  }

  ngOnInit(): void {
  }
  // addTag() { 
  //   this.token = this.ps.getToken();console.log(this.token,'this.token');
  //   this.metaService.addTag({ name: 'csrf-token', content: this.token });
  // }
  submitLogin(){
        this.ps.getToken(this.email,this.pass);
        // this.ps.Login(this.email,this.pass);
  }
}
