import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { DataService } from './../services/data.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
@Component({
  selector: 'app-uploadcsv',
  templateUrl: './uploadcsv.component.html',
  styleUrls: ['./uploadcsv.component.css']
})
export class UploadcsvComponent implements OnInit {

  constructor(private route:Router,private spinner:NgxSpinnerService,private toast:ToastrService,private fb:FormBuilder,private dataservice:DataService) { }
  submitted = false;
  uploadcsv = this.fb.group({
    file: ["", [Validators.required]],
  });

   file;
   progress: number = 0;
  ngOnInit(): void {
    this.getfiles();
  }
  get registerFormControl() {
    return this.uploadcsv.controls;
  }
  getfiles()
  {
    this.dataservice.getUploadFiles().subscribe((res: any)=>{
      console.log(res);
     });
  }

  uploadCSV(file:any)
  {
    //console.log(file.target.files);
    this.file=file.target.files[0];
    //console.log(this.file);
  }

  onSubmit()
  {
    this.submitted = true;
    if(this.uploadcsv.valid){
      const formData = new FormData(); 
      formData.append('file',this.file); 
      this.spinner.show();
      this.dataservice.uploadCsv(formData).subscribe((res: any)=>{
        try{
           this.spinner.hide();
           this.toast.success("upload successfully");
           this.route.navigate(['']);
        }
        catch(e)
        {
          this.toast.error("something went wrong");
        }
      });
     
    }
  }   

}
