<div class="table-section" >
   <!-- partial:index.partial.html -->
<div class="container">
   <div class="row">
      <div class="col-md-6">
         <div id="custom-search-input" >
                <div class="input-group">
                    <!-- <input type="text" class="search-query form-control" placeholder="Search" /> -->
                    <!-- <span class="input-group-btn">
                        <button type="button" disabled>
                            <span class="fa fa-search" ></span>
                        </button>
                    </span> -->
                </div>
            </div>
            
      </div>
      <div class="col-md-6">
         <!-- <label for="file"><span (click)="upload()">Upload CSV</span></label> -->
           <!-- Button trigger modal -->
           <button type="button" (click)="openmodal()"  id="myBtn" class="btn btn-primary" >
           UploadCSV
          </button>
 
 <!-- Modal -->
 <div id="myModal" class="modal">

   <!-- Modal content -->
   <div class="modal-content">
      <div class="modal-header">
         <div class="title">Upload Csv</div> 
         <div class="close"><span (click)="closemodal()" class="cross">&times;</span></div>
           </div>
        <div class="form-wrap">
           <app-uploadcsv></app-uploadcsv>
        </div> 
   </div>
 
 </div>
 
      </div>
   </div>
   
   <div class="row">
      <div class="col-md-12">
      	<div class="input-group">
		      		<select id="select-type-basic" [(ngModel)]="filter_tag" (change)="TagChanged($event.target.value)" class=" form-control">
		      			<option [value]="0">
					      -----Please select tag------
					    </option>
		      		 <option *ngFor="let order of columns; let i = index" [value]="order">
					      {{order}}
					    </option>
					      	</select>
                    <input *ngIf="search_ready" type="text" [(ngModel)]="filter_tag_value" (keypress)="SearchTag($event)"  class="search-query form-control" placeholder="{{placeholder}}" />
                    <!-- <mat-form-field appearance="fill">
  <mat-label>Toppings</mat-label>
  <mat-select [formControl]="toppings" multiple>
    <mat-select-trigger>
      {{toppings.value ? toppings.value[0] : ''}}
      <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
        (+{{toppings.value.length - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
  </mat-select>
</mat-form-field> -->
                    <!-- <span class="input-group-btn">
                        <button type="button" disabled>
                            <span class="fa fa-search" ></span>
                        </button>	
                    </span> -->
                </div>
      	
   <table class="table table-striped">
      <thead>
         <tr>
            <th><i class="fa fa-trash" style="font-size: 27px; color: #535353;"></i></th>
            <th><div class="form-check">
              <input class="form-check-input" type="checkbox" value="" style="width: 23px;height: 2em;margin-top: -31px;">
            	</div>
        	</th>
            <th>Agent Name</th>
            <th>Mobile</th>
            <th>Log Type</th>
            <th>Name</th>
            <th>City</th>
            
         </tr>
      </thead>
      <tbody>
         <tr *ngFor="let report of reportsData; let i = index">
            <td><i class="fa fa-file" style="font-size: 27px; color: #535353;"></i></td>
            <td><div class="form-check">
              <input class="form-check-input" type="checkbox" value="" style="width: 23px;
    height: 2em;
    margin-top: -1px;">

            </div></td>
            <td><b>{{report['Agent name']}}</b><br><span class="font-wept">Log Time {{report['Log Time']}}</span></td>
            <td>{{report.Phone}}
            </td>
           <td><button class="btn"><i class="fa fa-download"></i> {{report['Log Type']}}</button></td>
           <td>{{report['First Name']}}
            </td>
            <td>{{report['City']}}
            </td>
            <!-- <td>
            <span class="p-20">Yes</span> <span class="p-22">NO</span>   
            </td> -->
         </tr>
        
      </tbody>
   </table>

      </div>
   </div>
</div>
<!-- partial -->
</div>