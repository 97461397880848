import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from './../services/data.service';
import { Router, ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  files;

  constructor(private toast:ToastrService,private router:Router,private dataservice:DataService) { }

  ngOnInit(): void {
    this.getfiles();
  }
   
  openfile(id)
  {
    this.router.navigate(['csvview',id]);
  }
  getfiles()
  {
    this.dataservice.getUploadFiles().subscribe((res: any)=>{
     try{
         this.files=res.data;
         //console.log(this.files);
     }
     catch(e)
     {
      this.toast.error("someThing Went Wrong");
     }
     });
  }


}
