<!-- <div *ngIf="loaderState" class="loader">
    <img width="150px" src="assets/images/loader.svg" />
</div> -->
<!-- <script>
  angular.module("app").constant("CSRF_TOKEN", '{{ csrf_token() }}');
</script>
<app-login></app-login> -->
<app-header></app-header>

<div class="content_area">
  <router-outlet></router-outlet>
</div>

<!-- <flash-messages></flash-messages>
<kit-save-bar [config]="saveBarConfig" *ngIf="saveBarConfig?.status == 'show'" [@slideInOut]></kit-save-bar> -->
<app-footer></app-footer>
