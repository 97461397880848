<!-- Footer -->
<footer class="page-footer font-small blue">

  <!-- Copyright -->
  <div class="footer-copyright text-center py-3">© 2020 Copyright:
    <a href="#"> reports.com</a>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->