import { Injectable } from '@angular/core';
import { HttpHeaders,HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DataService {
	uri:string;
  constructor(private http:HttpClient) { 
  this.uri = 'http://analyze.rankhop.com/laravel_backend/public';
  }

  getRportsData(){
    let url = `${this.uri}/reports`;
    let obj = {};
   //obj.token = '';
  	return this.http.post(url,obj);
  }
  getRportsDataByTag(dataobj){
  	 let url = `${this.uri}/reports_bytag`;
  	return this.http.post(url,dataobj);
  }
  getDistinctValues(tagName) {
  	 let url = `${this.uri}/distinct`;
  	 let dataobj = {};
  	 dataobj['field'] = tagName;
  	return this.http.post(url,dataobj);
  }
  uploadCsv(data)
  {
    let url = `${this.uri}/api/file/upload`;
    let headers = new HttpHeaders();
    let header={
      "Content-Type":"multipart/form-data"
    }
  	return this.http.post(url,data);
  }
  getUploadFiles()
  {
    let url = `${this.uri}/api/file`;
  	return this.http.get(url);
  }
  getFileData(id)
  {
    let url = `${this.uri}/api/file/v3/${id}`;
  	return this.http.get(url);
  }
}
