import { Component, OnInit ,ViewChild} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataService } from './../services/data.service';
import { Router, ActivatedRoute } from "@angular/router";
import { AgGridAngular } from 'ag-grid-angular';
@Component({
  selector: 'app-viewcsvdetails',
  templateUrl: './viewcsvdetails.component.html',
  styleUrls: ['./viewcsvdetails.component.css']
})
export class ViewcsvdetailsComponent implements OnInit {

  constructor(private dataservice:DataService,private toast:ToastrService,private router:Router,private activateroute:ActivatedRoute) { 
    this.params=this.activateroute.params;

  }

  defaultColDef = {
      sortable: true,
      filter: true
  };

  columnDefs;
  rowData;
  params;
  filesdata;
  paginationPageSize =10;
  pivotMode = true;
   cols;
   rows;
  
  ngOnInit(): void {

    this.getfilesdata();
  }
  getfilesdata()
  {
    let id =this.params._value.id;
    this.dataservice.getFileData(id).subscribe((res: any)=>{
      try{
        this.columnDefs=res.data.columnDefs;
        this.rowData=res.data.rowdata;
         console.log(res);
      }
      catch(e)
      {
       this.toast.error("someThing Went Wrong");
      }
      });
  }

}
