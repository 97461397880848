<div class="table-section" >
    <!-- partial:index.partial.html -->
 <div class="container">
    <div class="row">
       <div class="col-md-6">
          <div id="custom-search-input" >
                 <div class="input-group">
                 </div>
             </div>
             
       </div>
      
    </div>
    <div class="row">
       <div class="col-md-12">
          
           
    <table class="table table-striped">
       <thead>
          <tr>
             <th><i class="fa fa-trash" style="font-size: 27px; color: #535353;"></i></th>
             <!-- <th><div class="form-check">
               <input class="form-check-input" type="checkbox" value="" style="width: 23px;height: 2em;margin-top: -31px;">
                 </div>
             </th> -->
             <th>File Name</th>
             <th>Date</th>
            
             
          </tr>
       </thead>
       <tbody>
          <tr *ngFor="let report of files; let i = index">
             <td><i class="fa fa-file" style="font-size: 27px; color: #535353;"></i></td>
             <td (click)="openfile(report.id)"><b>{{report.title}}</b><br></td>
             <td>{{report.created_at}}
             </td>
            <!-- <td><button class="btn"><i class="fa fa-download"></i> {{report['Log Type']}}</button></td>
            <td>{{report['First Name']}}
             </td>
             <td>{{report['City']}}
             </td> -->
          </tr>
         
       </tbody>
    </table>
 
       </div>
    </div>
 </div>
 <!-- partial -->
 </div>