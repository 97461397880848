import { NgModule } from '@angular/core';
import { BrowserModule ,Meta} from '@angular/platform-browser';
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from "./auth.service";
import { RegistrationComponent } from './registration/registration.component';
import { AppRoutingModule } from './app-routing.module';
import { ReportsComponent } from './reports/reports.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import { UploadcsvComponent } from './uploadcsv/uploadcsv.component';


import { ToastrModule } from 'ngx-toastr';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ViewcsvdetailsComponent } from './viewcsvdetails/viewcsvdetails.component';
import { AgGridModule } from 'ag-grid-angular';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    RegistrationComponent,
    ReportsComponent,
    UploadcsvComponent,
    DashboardComponent,
    ViewcsvdetailsComponent
  ],
  imports: [
    BrowserModule,
    AgGridModule.withComponents([]),
    FormsModule,
   
    HttpClientModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatSelectModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
  providers: [AuthService,Meta],
  bootstrap: [AppComponent]
})
export class AppModule { }
