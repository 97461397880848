import { Injectable } from '@angular/core';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  uri = 'http://localhost:8000';
  constructor(private http: HttpClient,private router:Router) {
  var mail = localStorage.getItem('email');
  console.log('mail',mail)
  }

  // Login(email,pass){
  //   const obj = {
  //     email,
  //     pass
  //   };
  //   this.http.post(`${this.uri}/login`, obj)
  //       .subscribe(res => console.log('Done'));
  // }
  getToken(email,pass){
    return this.http.get(`${this.uri}/getToken`)
        .subscribe((res:any) => {  
        console.log('toekn',res)
          if (res.status ==200){
            const httpOptions = {
              headers: new HttpHeaders({ 'Content-Type': 'application/json','Access-Control-Allow-Origin':'*','X-CSRF-TOKEN':res.token })
            };
            const obj = {
              email,
              pass
            };
            this.http.post(`${this.uri}/login`, obj,httpOptions)
                .subscribe(res => {
                  console.log('Done');
                  localStorage.setItem('email', email);
                  //localStorage.setItem('token', email);
                  this.router.navigate(['/reports'])
                }

                );
          }
        });
  }
 
}
