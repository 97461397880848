<div class="container">
    <div class="row">
      <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
        <div class="card card-signin my-5">
          <div class="card-body">
            <h5 class="card-title text-center">Sign In</h5>
            <br>
            <form class="form-signin">
              <div class="form-label-group">
                <input type="email" name="email" [(ngModel)]="email" class="form-control" placeholder="Email address" required autofocus>
                <!-- <label for="inputEmail">Email address</label> -->
              </div>
<br>
              <div class="form-label-group">
                <input type="password" name="password" [(ngModel)]="pass" class="form-control" placeholder="Password" required>
                <!-- <label for="inputPassword">Password</label> -->
              </div>

             <br>
              <button class="btn btn-lg btn-primary btn-block text-uppercase" (click)="submitLogin()" type="submit">Sign in</button>
              <hr class="my-4">
              </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  
 